define('imageApi', ['http'], function (http) {

	var getImageOptions = function (imageIDs, categoryIDs) {
		var params = {
			imageIDs: imageIDs,
			categoryIDs: categoryIDs
		};
		return apihttpHelper.get('/api/images/imageoptions', params).then(processListResponse);
	};

	var getImage = function (imageID) {
		var params = {
			imageID: imageID
		};
		return http.get('/api/images/find', params).then(processFindResponse);
	};

	var processResponse = function (response) {
		return response.Data;
	};

	return {
		getImageOptions: getImageOptions,
		getImage: getImage
	};
});
define('tagApi', ['http'], function (http) {

	var list = function () {
		return http.get('/api/projects/perspectives/hotspots/groups/templates/tags/list').then(processListResponse);
	};

	var processListResponse = function (response) {
		return response.Data;
	};

	return {
		list: list
	};
});
define('templateApi', ['http'], function (http) {

	var getTemplatesForHotspotTemplateGroup = function (id) {
		var params = {
			templateGroupID: id
		};
		return http.get('/api/projects/perspectives/hotspots/groups/templates/list', params).then(processResponse);
	};

	var getTemplate = function (id) {
		var params = {
			id: id
		};
		return http.get('/api/projects/perspectives/hotspots/groups/templates/details', params).then(processResponse);
	};

	var processResponse = function (response) {
		return response.Data;
	};

	return {
		getTemplatesForHotspotTemplateGroup: getTemplatesForHotspotTemplateGroup,
		getTemplate: getTemplate
	};
});